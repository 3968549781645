import PropTypes from 'prop-types';
import React from 'react';
import { Dialog } from 'sw-ui';

import { LABELS_UPLOAD_CONTACTS } from '../../../bi/constants/amo';
import COMPONENTS from '../../../bi/constants/components';
import { DECISIONBUTTONS } from '../../../bi/constants/decisionButtons';
import DecisionButtons from '../../DecisionButtons';

import styles from '../styles/dialog.module.css';

const СonfirmationDialog = ({ file, onCloseDialog, uploadFiles }) => (
  <Dialog
    onClick={ onCloseDialog }
  >
    <div className={ styles.modal_wrapper }>
      <div className={ styles.text_wrapper }>
        { LABELS_UPLOAD_CONTACTS.MODAL_QUESTIONS(file.name) }
      </div>
      <div className={ styles.button_modal_wrapper }>
        <DecisionButtons
          onCancel={ () => onCloseDialog() }
          onSave={ () => uploadFiles(file) }
          tooltipPosition={ COMPONENTS.TOOLTIP.POSITION.TOP }
          labelSave={ DECISIONBUTTONS.LABELS.CONFIRM }
        />
      </div>
    </div>
  </Dialog>
);

СonfirmationDialog.propTypes = {
  file: PropTypes.object.isRequired,
  onCloseDialog: PropTypes.func.isRequired,
  uploadFiles: PropTypes.func.isRequired,
};

export { СonfirmationDialog };
