const TAXI_CLASSES = {
  ECONOMY: 1,
  COMFORT: 2,
  COMFORT_PLUS: 3,
  BUSINESS: 4,
  CHILDREN: 5,
  MINIVAN: 6,
  CRUISE: 7,
  PREMIER: 8,
};

const TAXI_CLASSES_TITLE = {
  [TAXI_CLASSES.ECONOMY]: 'Эконом',
  [TAXI_CLASSES.COMFORT]: 'Комфорт',
  [TAXI_CLASSES.COMFORT_PLUS]: 'Комфорт+',
  [TAXI_CLASSES.BUSINESS]: 'Бизнес',
  [TAXI_CLASSES.MINIVAN]: 'Минивен',
  [TAXI_CLASSES.CHILDREN]: 'Детский',
  [TAXI_CLASSES.CRUISE]: 'Круиз',
  [TAXI_CLASSES.PREMIER]: 'Премьер',
};

export {
  TAXI_CLASSES,
  TAXI_CLASSES_TITLE,
};
