import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Dialog, Loading, Select } from 'sw-ui';

import DecisionButtons from '../../../../../components/DecisionButtons';

import { AGGREGATION_IDS,
  LABEL_ACCOUNT_SETTINGS_FIELDS,
  ACCOUNT_SETTINGS_FIELDS,
} from '../../../../../bi/constants/account';

import styles from '../styles/index.module.css';

const WIDTH = '600px';

const ACCOUNTID = 'accountId';

const LABELS = {
  ACCOUNT_SETTINGS: 'Настройки аккаунта',
  EMPTY_SETTINGS: ' Настройки аккаунта времено недоступны',
};

const remindExpenseReportValues = [
  { label: 'через 3 дня', value: 'After3Days' },
  { label: 'никогда', value: 'Never' },
];

const AccountSettings = ({
  aggregationId,
  accessSettingsChange,
  accountSettings,
  loading,
  onChange,
  onSave,
  onClose,
  onChangeSelect,
  hideSettings,
}) => {
  const hideSettingsKeys = Object
    .entries(hideSettings)
    .reduce((prev, [key, show]) => (!show ? [...prev, key] : prev), []);

  const renderAccountSettings = ([name, value], section, index) => {
    const renderSelectHtml = () => LABEL_ACCOUNT_SETTINGS_FIELDS[name] && (
      <div className={ `${styles.settings_name} ${styles.select_row}` } key={ name + index }>
        <span>
          {LABEL_ACCOUNT_SETTINGS_FIELDS[name]}
        </span>
        <div className={ styles.settings_select }>
          <Select
            items={ remindExpenseReportValues }
            value={ value }
            theme={ 'search' }
            className={ styles.select }
            onChange={ item => onChangeSelect(section, name, item) }
          />
        </div>
      </div>
    );

    const accountSettingsHtml = () => LABEL_ACCOUNT_SETTINGS_FIELDS[name] && (
      <div className={ styles.account_settings_wrap } key={ index }>
        <div>
          <Checkbox
            onChange={ () => onChange(section, name) }
            disabled={ !accessSettingsChange }
            value={ value }
          />
        </div>
        <div className={ styles.account_settings_name }>{LABEL_ACCOUNT_SETTINGS_FIELDS[name]}</div>
      </div>
    );

    const checkExpenseReport = name === ACCOUNT_SETTINGS_FIELDS.REMIDN_EXPENSE_REPORT ? renderSelectHtml() : accountSettingsHtml();
    const checkValue = name !== ACCOUNT_SETTINGS_FIELDS.NO_ANALYTICS ? checkExpenseReport : null;

    return (
      (aggregationId === AGGREGATION_IDS.CONTUR) ||
      (accountSettings.accountId === AGGREGATION_IDS.PPR) ||
      (accountSettings.accountId === AGGREGATION_IDS.CKR)
        ? accountSettingsHtml()
        : checkValue
    );
  };


  const emptyAccountSettings = Object.keys(accountSettings).length !== 0;
  const mapperAccountSettings = value => Object.entries(value);
  const checkItem = item => mapperAccountSettings(item[1])
    .filter(([fieldName]) => !hideSettingsKeys.includes(fieldName))
    .map((el, index) => renderAccountSettings(el, item[0], index));

  const renderHtmlSection = item => item[0] !== ACCOUNTID && !!LABEL_ACCOUNT_SETTINGS_FIELDS[item[0]] && (
    <div key={ item[0] }>
      <div className={ styles.account_settings_subtitle }> {LABELS.HIDE_SECTIONS} </div>
      <div className={ styles.account_settings_container }>
        {LABEL_ACCOUNT_SETTINGS_FIELDS[item[0]]}
        {checkItem(item)}
      </div>
    </div>
  );

  const mapList = () => mapperAccountSettings(accountSettings).map(item => renderHtmlSection(item));
  const checkAccountSettings = emptyAccountSettings ? mapList() : LABELS.EMPTY_SETTINGS;

  const loadingHtml = (
    <div className={ styles.loading }>
      <Loading size='large' />
    </div>
  );
  const settingsDialogHtml = (
    <div className={ styles.dialog_account_settings }>
      <div className={ styles.account_settings_header }>
        <h3>{LABELS.ACCOUNT_SETTINGS}</h3>
      </div>
      {checkAccountSettings}
      <DecisionButtons
        loadingIncluded
        onSave={ () => onSave() }
        onCancel={ () => onClose() }
      />
    </div>
  );

  const isLoad = loading ? loadingHtml : settingsDialogHtml;

  return (
    <Dialog
      width={ WIDTH }
      onClick={ onClose }
    >
      { isLoad }
    </Dialog>
  );
};

AccountSettings.propTypes = {
  aggregationId: PropTypes.number.isRequired,
  accountSettings: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  accessSettingsChange: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onChangeSelect: PropTypes.func.isRequired,
  hideSettings: PropTypes.objectOf(PropTypes.bool),
};

AccountSettings.defaultProps = {
  loading: true,
  accountSettings: {},
  onChange: () => {},
  onSave: () => {},
  onClose: () => {},
  hideSettings: {},
};

export default AccountSettings;
