import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'sw-ui';

import COMPONENTS from '../../bi/constants/components';

import ButtonGreen from '../../components/button/Button';
import TextArea from '../../components/textarea';

import { ItemTemplateWarning } from './components/itemTemplateWarning';

import styles from './index.module.css';

const { BUTTON: { THEME: { SECOND } } } = COMPONENTS;

const CONTENT_MAX_LENGTH = 10;
const TEXTAREA_COUNT_ROWS = 5;

const LABELS = {
  ADD_TEMPLATE: 'Добавить шаблон',
  SAVE_TEMPLATE: 'Сохранить шаблон',
  SAVE_AND_SEND_TEMPLATE: 'Сохранить и отправить шаблон в ЛК',
  CANCEL: 'Отменить',
  CLOSE: 'Закрыть',
  NO_TEMPLATES: 'НЕТ ШАБЛОНОВ',
  SERVER_ERROR: 'ОШИБКА СЕРВЕРА',
};

const WarningForm = ({
  onClose,
  getListTemplateWarning,
  addFieldTemplateWarning,
  handleTextAreaChange,
  changeFieldTemplateWarning,
  deleteFieldTemplateWarning,
  handleTemplateWarning,
  handleEditTemplate,
  listTemplateWarning,
  isError,
  isEditing,
  isNewTemplateWarning,
  textNewTemplateWarning,
}) => {
  useEffect(() => {
    getListTemplateWarning();
  }, []);

  const handleCloseForm = () => {
    onClose();
    handleEditTemplate(false);
  };

  const renderNewWarningTemplate = () => {
    if (isError) return null;

    const disabledButton = textNewTemplateWarning.length < CONTENT_MAX_LENGTH;

    if (!isNewTemplateWarning) {
      return (
        <ButtonGreen
          label={ LABELS.ADD_TEMPLATE }
          disabled={ isEditing }
          onClick={ () => handleTemplateWarning(true) }
        />
      );
    }

    return (
      <section className={ styles.wrapper_newTemplate }>
        <TextArea
          className={ `${styles.textarea_newTemplate} al-textarea` }
          value={ textNewTemplateWarning }
          onChange={ value => handleTextAreaChange(value) }
          rows={ TEXTAREA_COUNT_ROWS }
        />
        <div className={ styles.action_newTemplate }>
          <ButtonGreen
            label={ LABELS.SAVE_TEMPLATE }
            onClick={ () => addFieldTemplateWarning(false) }
            disabled={ disabledButton }
          />
          <ButtonGreen
            label={ LABELS.SAVE_AND_SEND_TEMPLATE }
            disabled={ disabledButton }
            onClick={ () => addFieldTemplateWarning(true) }
          />
          <Button
            className={ styles.button }
            theme={ SECOND }
            onClick={ () => handleTemplateWarning(false) }
          >
            { LABELS.CANCEL }
          </Button>
        </div>
      </section>
    );
  };

  const renderListTemplateWarning = () => {
    const valueContent = (content) => {
      if (!content.text) {
        return '';
      }

      return content.text;
    };

    if (isError) {
      return <p className={ styles.error }>{ LABELS.SERVER_ERROR }</p>;
    }

    if (!listTemplateWarning.length) {
      return <p className={ styles.no_templates }>{ LABELS.NO_TEMPLATES }</p>;
    }

    return listTemplateWarning.map(({ id, content, isActive }) => (
      <ItemTemplateWarning
        key={ id }
        id={ id }
        content={ valueContent(content) }
        isActive={ isActive }
        isEditingAll={ isEditing }
        onChange={ changeFieldTemplateWarning }
        onRemove={ deleteFieldTemplateWarning }
        onEdit={ value => handleEditTemplate(value) }
      />
    ));
  };

  return (
    <div className={ styles.wrapper }>
      <Button
        className={ `${styles.button} ${styles.button_close}` }
        theme={ SECOND }
        onClick={ handleCloseForm }
      >
        { LABELS.CLOSE }
      </Button>
      { renderNewWarningTemplate() }
      { renderListTemplateWarning() }
    </div>
  );
};

WarningForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  getListTemplateWarning: PropTypes.func.isRequired,
  addFieldTemplateWarning: PropTypes.func.isRequired,
  handleTextAreaChange: PropTypes.func.isRequired,
  changeFieldTemplateWarning: PropTypes.func.isRequired,
  deleteFieldTemplateWarning: PropTypes.func.isRequired,
  handleTemplateWarning: PropTypes.func.isRequired,
  handleEditTemplate: PropTypes.func.isRequired,
  listTemplateWarning: PropTypes.array.isRequired,
  isError: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isNewTemplateWarning: PropTypes.bool.isRequired,
  textNewTemplateWarning: PropTypes.string.isRequired,
};

export { WarningForm };
