const HTTPCODES = {
  OK: 200,
  BADREQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOTFOUND: 404,
  CONFLICT: 409,
};

export default HTTPCODES;
