import React from 'react';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';

import Navigation from './components/navigation';
import MainNavigation from './components/mainNavigation';

import styles from './styles/company.module.scss';

const Components = (props) => {
  const {
    params,
    userService,
    accountService,
    companyService,
    hotelsService,
    notificationsService,
    toNewTrip,
    toUserCartsAndNote,
    toAnotherTab,
    toAnotherCompany,
    airService,
    tripService,
    featureFlagsService,
    staticsService,
    employeeService,
    toDocumentsConstructor,
  } = props;

  return (
    <div className={ styles.wrap }>
      <MainNavigation
        companyService={ companyService }
        notificationsService={ notificationsService }
        accountService={ accountService }
        toAnotherTab={ toAnotherTab }
        params={ params }
        toAnotherCompany={ toAnotherCompany }
        featureFlagsService={ featureFlagsService }
        userService={ userService }
        staticsService={ staticsService }
        employeeService={ employeeService }
        tripService={ tripService }
      />
      <Navigation
        companyService={ companyService }
        employeeService={ employeeService }
        hotelsService={ hotelsService }
        notificationsService={ notificationsService }
        accountService={ accountService }
        airService={ airService }
        tripService={ tripService }
        toNewTrip={ toNewTrip }
        toDocumentsConstructor={ toDocumentsConstructor }
        toUserCartsAndNote={ toUserCartsAndNote }
        toAnotherTab={ toAnotherTab }
        params={ params }
        featureFlagsService={ featureFlagsService }
      />
    </div>
  );
};

Components.propTypes = {
  params: PropTypes.object.isRequired,
  userService: PropTypes.object.isRequired,
  accountService: PropTypes.object.isRequired,
  companyService: PropTypes.object.isRequired,
  notificationsService: PropTypes.object.isRequired,
  toNewTrip: PropTypes.func.isRequired,
  toUserCartsAndNote: PropTypes.func.isRequired,
  toAnotherTab: PropTypes.func.isRequired,
  toAnotherCompany: PropTypes.func.isRequired,
  airService: PropTypes.object.isRequired,
  tripService: PropTypes.object.isRequired,
  featureFlagsService: PropTypes.object.isRequired,
  hotelsService: PropTypes.object.isRequired,
  staticsService: PropTypes.object.isRequired,
  employeeService: PropTypes.object.isRequired,
  toDocumentsConstructor: PropTypes.func.isRequired,
};

const renderComponents = (box, opts) => {
  const root = createRoot(box);

  root.render(
    <Components
      params={ opts.params }
      appService={ opts.appService }
      userService={ opts.userService }
      accountService={ opts.accountService }
      companyService={ opts.companyService }
      notificationsService={ opts.notificationsService }
      airService={ opts.airService }
      tripService={ opts.tripService }
      toNewTrip={ opts.toNewTrip }
      toUserCartsAndNote={ opts.toUserCartsAndNote }
      toAnotherTab={ opts.toAnotherTab }
      toAnotherCompany={ opts.toAnotherCompany }
      featureFlagsService={ opts.featureFlagsService }
      hotelsService={ opts.hotelsService }
      staticsService={ opts.staticsService }
      employeeService={ opts.employeeService }
      toDocumentsConstructor={ opts.toDocumentsConstructor }
    />
  );

  return root;
};

export default renderComponents;
