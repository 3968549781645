import Rise from 'rise';
import ReactDOM from 'react-dom/client';
import {
  APPOPTS, initApp, checkUser, initAppModule,
} from './app';

import { renderApp } from './AppModule';
import { AppV2 } from './v2/AppV2';

import { pathV2Includes, versions } from './v2/utils/routes';
import { history } from './v2/utils/history';

const rootElement = document.getElementById('app');
const rootElementV2 = document.getElementById('appV2');

const versionOffClass = 'versionOff';

const initializedVersions = [];

const initReactDom = (stores, navigate) => {
  if (!stores || initializedVersions.includes(versions.old)) return;

  ReactDOM.createRoot(rootElement).render(
    renderApp(stores, navigate),
  );

  initializedVersions.push(versions.old);
};

const initReactDomV2 = () => {
  if (initializedVersions.includes(versions.v2)) return;

  ReactDOM.createRoot(rootElementV2).render(AppV2());

  initializedVersions.push(versions.v2);
};

const versionClassSwitcher = (version, stores, navigate) => {
  switch (version) {
    case versions.v2:
      initReactDomV2();
      rootElementV2.classList.remove(versionOffClass);
      rootElement.classList.add(versionOffClass);

      return;
    case versions.old:
    default:
      initReactDom(stores, navigate);
      rootElement.classList.remove(versionOffClass);
      rootElementV2.classList.add(versionOffClass);
  }
};


document.addEventListener('DOMContentLoaded', () => {
  let stores = null;

  APPOPTS.user.check = checkUser;

  window.Rise = new Rise(APPOPTS);
  const core = window.Rise;

  stores = initApp(core);
  initAppModule(core);

  core.start();

  const version = pathV2Includes();

  versionClassSwitcher(version, stores, core.navigate);

  history.listen(({ location }) => {
    versionClassSwitcher(pathV2Includes(location.pathname), stores, core.navigate);
  });
});
