import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Loading, Button } from 'sw-ui';

import { СonfirmationDialog } from './components/dialog';

import COMPONENTS from '../../bi/constants/components';

import styles from './styles/index.module.css';

const { BUTTON: { THEME: { WHITE } } } = COMPONENTS;

const BLANKFILE = {
  name: '',
  type: '',
  size: 0,
};

const ONE_MB_IN_BYTES = 1024 * 1024;

const MAX_SIZE = {
  TEN_MB: 10,
};

class UploadForm extends PureComponent {
  static propTypes = {
    extensions: PropTypes.arrayOf(PropTypes.string),
    onFileAdded: PropTypes.func,
    onFileRemoved: PropTypes.func,
    uploaded: PropTypes.bool,
    chooseFileLabel: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    invalidExtension: PropTypes.string.isRequired,
    file: PropTypes.object,
    validationFunc: PropTypes.func,
    className: PropTypes.string,
    modalFileUpload: PropTypes.bool,
    isMaxSize: PropTypes.bool,
  };

  static defaultProps = {
    extensions: [],
    uploaded: false,
    onFileAdded: () => {},
    onFileRemoved: () => {},
    validationFunc: () => {},
    file: BLANKFILE,
    className: '',
    modalFileUpload: false,
    isMaxSize: false,
  };

  constructor(props) {
    super(props);
    const { file } = this.props;
    const fileExist = file ? file.size > 0 : BLANKFILE.size > 0;

    this.state = {
      openDialog: false,
      file: file || BLANKFILE,
      selected: fileExist,
      highlight: false,
      valid: true,
    };
  }

  inputRef = null;

  setInputRef = (ref) => {
    if (ref) {
      this.inputRef = ref;
    }
  };

  openFileManager = () => {
    if (this.inputRef) {
      this.inputRef.click();
    }
  };

  selectFile = ({ target: { files } }) => {
    const { selected } = this.state;
    const hasNoFiles = !files.length;

    if (hasNoFiles || selected) {
      return;
    }

    this.validateAndAddFile(files[0]);
  };

  handleDragOver = (event) => {
    event.preventDefault();

    const { selected } = this.state;

    if (selected) {
      return;
    }

    this.setState({ highlight: true, valid: true });
  };

  handleDragLeave = () => {
    const { selected } = this.state;

    if (selected) {
      return;
    }

    this.setState({ highlight: false });
  };

  handleDrop = (event) => {
    event.preventDefault();

    const { selected } = this.state;
    const { dataTransfer: { files } } = event;
    const hasNoFiles = !files.length;

    if (hasNoFiles || selected) {
      return;
    }

    this.validateAndAddFile(files[0]);
  };

  validate = (name) => {
    const { extensions } = this.props;

    const parts = name.split('.');
    const extension = parts.length > 0 ? parts[parts.length - 1].toLowerCase() : '';

    const hasRequiredExtensions = extensions.length > 0;
    const extensionMatchesRequired = extensions.includes(extension);

    return !!(!hasRequiredExtensions || extensionMatchesRequired);
  };

  validateSize = (size) => {
    const bytesToMegabytes = size / ONE_MB_IN_BYTES;

    return bytesToMegabytes < MAX_SIZE.TEN_MB;
  }

  handleCloseDialog = () => {
    this.setState({ openDialog: false });
    this.inputRef.value = '';
  };

  uploadFiles = (file) => {
    const { onFileAdded } = this.props;
    const { name, size, type } = file;

    this.setState({
      file: {
        name,
        type,
        size,
      },
      selected: true,
      highlight: false,
      valid: true,
    }, () => onFileAdded(file));

    this.handleCloseDialog();
  };

  openConfirmationDialog = (file) => {
    this.setState({ openDialog: true, file });
  };

  validateAndAddFile = (files) => {
    const { name, size } = files;
    const { modalFileUpload, isMaxSize } = this.props;

    const isValid = this.validate(name) && (this.validateSize(size) || !isMaxSize);

    if (!isValid) {
      this.setState({
        file: {
          ...BLANKFILE,
        },
        valid: false,
        highlight: false,
      });

      return;
    }

    this.setState({
      file: {
        name,
      },
    });

    if (modalFileUpload) {
      this.openConfirmationDialog(files);
    } else {
      this.uploadFiles(files);
    }
  };

  removeFile = () => {
    const { onFileRemoved } = this.props;
    this.setState({
      file: {
        ...BLANKFILE,
      },
      selected: false,
    }, onFileRemoved);
  };

  renderInput = () => (
    <input
      type='file'
      ref={ this.setInputRef }
      className={ styles.input }
      onChange={ this.selectFile }
    />
  );

  renderStatus = () => {
    const { selected } = this.state;
    const { chooseFileLabel, description } = this.props;

    if (selected) {
      return null;
    }

    return (
      <div className={ styles.status }>
        <div className={ styles.upload_icon_wrap }>
          <i className={ `material-icons ${styles.upload_icon}` }>cloud_upload</i>
        </div>
        <div className={ styles.description_wrap }>
          <span className={ styles.description }>{ description }</span>
        </div>
        <div className={ styles.button_wrap }>
          <Button
            label={ chooseFileLabel }
            theme={ WHITE }
            onClick={ this.openFileManager }
          />
        </div>
      </div>
    );
  };

  renderFileInfo = () => {
    const {
      selected,
      file: {
        name,
      },
    } = this.state;
    const { uploaded } = this.props;

    if (!selected) {
      return null;
    }

    const loaderHtml = (
      <div className={ styles.loader }>
        <Loading size='small' />
      </div>
    );

    const fileIconHtml = (
      <div className={ styles.file_icon_wrap }>
        <i className={ `material-icons ${styles.file_icon}` }>assignment</i>
      </div>
    );

    const iconHtml = uploaded ? fileIconHtml : loaderHtml;

    return (
      <div className={ styles.file }>
        { iconHtml }
        <span className={ styles.file_name }>{ name }</span>
        <div
          className={ styles.close }
          onClick={ this.removeFile }
        >
          <i className={ `material-icons ${styles.close_icon}` }>close</i>
        </div>
      </div>
    );
  };

  renderValidation = () => {
    const { valid } = this.state;
    const {
      extensions,
      validationFunc,
      invalidExtension,
    } = this.props;

    if (valid) {
      return null;
    }

    validationFunc();

    const availableExtensions = extensions.join(', ');

    return (
      <div className={ styles.validation }>
        <span>{ invalidExtension } {availableExtensions}</span>
      </div>
    );
  };

  renderConfirmationDialog = () => {
    const { openDialog, file } = this.state;

    if (!openDialog) {
      return null;
    }

    return (
      <СonfirmationDialog
        file={ file }
        onCloseDialog={ this.handleCloseDialog }
        uploadFiles={ this.uploadFiles }
      />
    );
  };

  render() {
    const { highlight } = this.state;
    const { className } = this.props;

    const highlightStyle = highlight ? ` ${styles.highlight}` : '';

    return (
      <div
        className={ `${styles.wrap}${highlightStyle} ${className}` }
        onDragOver={ this.handleDragOver }
        onDragLeave={ this.handleDragLeave }
        onDrop={ this.handleDrop }
      >
        { this.renderInput() }
        { this.renderStatus() }
        { this.renderFileInfo() }
        { this.renderValidation() }
        { this.renderConfirmationDialog()}
      </div>
    );
  }
}

export default UploadForm;
