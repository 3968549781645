const FULLTIME = 'YYYY-MM-DDTHH:mm:ss';
const UTC_FULLTIME = 'YYYY-MM-DD[T]HH:mm:ss[Z]';
const DATE_TIME_LTS = 'D/M/YYYY LTS';
const TIME_SECONDS = 'HH:mm:ss';
const DATE = 'YYYY-MM-DD';
const DATEWITHWEEKDAY = 'DD.MM.YYYY, dd';
const DEFAULTDATE = 'DD.MM.YYYY';
const SHORT_DEFAULT_DATE = 'DD.MM.YY';
const DEFAULTTIME = 'HH:mm';
const FORMATDATE = 'DD-MM-YYYY HH:mm';
const FORMATDATETIME = 'DD.MM.YYYY HH:mm';
const FULLPATTERN = 'D MMMM YYYY';
const FORMAT_DATE_FULL = 'DD.MM.YYYY HH:mm:ss';
const MONTHPATTERN = 'D MMMM';
const DAYPATTERN = 'D';
const MONTHYEARPATTERN = 'MMM YYYY';
const DAYMONTHPATTERN = 'DD.MM';
const DAYDAYPATTERN = 'dd';
const LOCALERU = 'ru';
const ACTION_IN_DATE_TIME_PATTERN = 'DD.MM.YYYY[,] dd [в] HH:mm';
const DATE_TIME_WITH_YEAR = 'D MMMM YYYY [в] HH:mm';
const DAY_OF_MONTH_WITH_TIME = 'D MMMM [в] HH:mm';
const DATE_WITHOUT_TIME_ZONE = 'YYYY-MM-DDTHH:mm';
const DAY_OF_MONTH_TIME = 'D MMMM HH:mm';
const DATE_SECOND_TIME = 'YYYY-MM-DD HH:mm:ss.ss';
const DATE_SECOND_SHORT_TIME = 'YYYY-MM-DD HH:mm:ss';
const DAY_OF_MONTH_WITH_YEAR_AND_TIME = 'D MMMM YYYY HH:mm';

export {
  DATE_WITHOUT_TIME_ZONE,
  FULLTIME,
  UTC_FULLTIME,
  DATEWITHWEEKDAY,
  DEFAULTDATE,
  DEFAULTTIME,
  FORMATDATE,
  FORMATDATETIME,
  FULLPATTERN,
  MONTHPATTERN,
  DAYPATTERN,
  MONTHYEARPATTERN,
  DATE,
  DAYMONTHPATTERN,
  DAYDAYPATTERN,
  LOCALERU,
  TIME_SECONDS,
  DATE_TIME_LTS,
  FORMAT_DATE_FULL,
  ACTION_IN_DATE_TIME_PATTERN,
  DAY_OF_MONTH_WITH_TIME,
  DATE_TIME_WITH_YEAR,
  DAY_OF_MONTH_TIME,
  DATE_SECOND_TIME,
  DATE_SECOND_SHORT_TIME,
  SHORT_DEFAULT_DATE,
  DAY_OF_MONTH_WITH_YEAR_AND_TIME,
};
