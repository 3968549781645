const AIRLINE_PROVIDERS = {
  AEROFLOT: 'aeroflot',
  S7: 's7',
  AMADEUS: 'amadeus',
  PORTBILET: 'portbilet',
  SIRENA: 'sirena',
  OFF: 'off',
  TAIS: 'tais',
  MIXVEL: 'mixvel',
};

const PROVIDER_OFF = [
  AIRLINE_PROVIDERS.SIRENA,
  AIRLINE_PROVIDERS.AMADEUS,
  AIRLINE_PROVIDERS.PORTBILET,
  AIRLINE_PROVIDERS.TAIS,
  AIRLINE_PROVIDERS.MIXVEL,
];

export {
  AIRLINE_PROVIDERS,
  PROVIDER_OFF,
};
